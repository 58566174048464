import * as React from 'react';
import { Box, Typography, ButtonBase } from '@mui/material';

import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

export type CardSectionProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  variant?: 'h4' | 'h5' | 'h6';
  color?: string;
};

const CardSection = (props: CardSectionProps): JSX.Element => {
  const { title, children, defaultOpen = true, variant = 'h4', color } = props;

  const [sectionDisplay, setSectionDisplay] = React.useState(defaultOpen);
  const toggleDisplay = () => setSectionDisplay(!sectionDisplay);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mt: '100px',
        }}
      >
        <Typography variant={variant} sx={{ color }}>
          {title}
        </Typography>
        <ButtonBase onClick={toggleDisplay} sx={{ color }}>
          {sectionDisplay ? (
            <KeyboardArrowUpIcon fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" />
          )}
        </ButtonBase>
      </Box>
      <Box sx={{ display: sectionDisplay ? 'block' : 'none' }}>{children}</Box>
    </>
  );
};
export default CardSection;
