import { Caffe, CaffePromise } from 'apiclient';
import { Product } from 'types/product';

const caffe = new Caffe();

export interface getProductDetailProps {
  shopSlug: string;
  productSlug: string;
}

export type getProductDetailRedirectReturn =
  | (Product & { disabled?: never; redirectDestination?: never })
  | { disabled: true; redirectDestination: string };

export const getProductDetailRedirect = async (
  props: getProductDetailProps,
): CaffePromise<getProductDetailRedirectReturn> => {
  const { shopSlug, productSlug } = props;

  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.product.query.product',
    params: {
      shop: shopSlug,
      product: productSlug,
      redirect: true,
    },
  });
};

export type getProductDetailReturn = Product;

const getProductDetail = async (
  props: getProductDetailProps,
): CaffePromise<getProductDetailReturn> => {
  const { shopSlug, productSlug } = props;

  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.product.query.product',
    params: {
      shop: shopSlug,
      product: productSlug,
    },
  });
};
export default getProductDetail;
