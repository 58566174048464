import React from 'react';
import Link from 'next/link';
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';

import { makeClasses, joinSx } from 'styles';
import { Product } from 'types/product';
import { getProductListReturn } from 'api';
import { RatingHeart, CardSection } from 'components/shared';
import { CoffeeRoastLevel } from '../CoffeeRoast';
import ProductImageDisplay from '../ProductImageDisplay';
import ProductContext from '../ProductContext';

const useStyles = makeClasses({
  cardMedia: {
    'borderRadius': '2px',
    // make card media square based on width
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
    'display': 'block',
    'backgroundSize': 'cover',
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'center',
  },
  rating: {
    color: 'secondary.main',
  },
  productTitle: {
    'textOverflow': 'ellipsis',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'fontFamily': 'fontFamilySansSerif',
    'fontWeight': 'fontWeightMedium',
    '&.MuiTypography-root': {
      fontSize: '1.1rem',
      color: 'secondary.main',
      marginBottom: '0.2em',
    },
  },
  productSeller: {
    fontSize: '0.8rem',
    color: 'secondary.main',
    fontWeight: 'fontWeightLight',
  },
  productTaste: {
    fontSize: '0.9rem',
    color: 'common.darkGrey',
    fontWeight: 'fontWeightLight',
    lineHeight: 1.3,
    height: '2.5rem',
    paddingTop: '2px',
    overflow: 'hidden',
  },
  productTasteSep: {
    padding: '0 0.5em',
  },
  productInfo: {
    fontSize: '1rem',
    color: 'common.darkGrey',
  },
  mainPriceDiscount: {
    fontWeight: 'fontWeightLight',
    textDecoration: 'line-through',
  },
  promoPrice: {
    paddingLeft: '0.3em',
    color: 'error.main',
    fontWeight: 'fontWeightMedium',
  },
  priceSep: {
    padding: '0 0.5em',
  },
  productCard: {
    'textAlign': 'center',
    'padding': 2,
    '& > div': {
      paddingTop: 0.5,
    },
    '& > p': {
      paddingTop: 0.5,
    },
  },
  productRate: {
    textAlign: 'center',
  },
  skeletonCard: {
    backgroundColor: 'common.lightGrey',
    borderRadius: '4px',
  },
  tasteSkeleton: {
    display: 'inline-block',
    marginLeft: 0.3,
    marginRight: 0.3,
  },
});

type productTasteProps = {
  tasteNote?: Product['tasteNotes'];
};
const ProductTaste: React.VFC<productTasteProps> = (props) => {
  const { tasteNote } = props;
  const classes = useStyles;

  if (!tasteNote) {
    return <></>;
  }

  return (
    <Typography sx={classes.productTaste}>
      {tasteNote.length > 0 ? (
        tasteNote.map((note, i, arr) => (
          <React.Fragment key={note.name}>
            <Box component="span" sx={{ color: note.color }}>
              {note.name}
            </Box>
            {arr.length - 1 !== i ? (
              <Box component="span" sx={classes.productTasteSep}>
                |
              </Box>
            ) : (
              ''
            )}
          </React.Fragment>
        ))
      ) : (
        <Box component="span" sx={{ height: '2.5rem' }}>
          <Skeleton width="3em" sx={classes.tasteSkeleton} />
          <Skeleton width="3em" sx={classes.tasteSkeleton} />
          <Skeleton width="3em" sx={classes.tasteSkeleton} />
        </Box>
      )}
    </Typography>
  );
};

type productPriceProps = {
  price: number;
  weight: number;
  unitSold: number;
  promoPrice: number | null;
};
function ProductPrice({
  price,
  weight,
  unitSold,
  promoPrice,
}: productPriceProps) {
  const classes = useStyles;

  return (
    <Typography sx={classes.productInfo}>
      <Box
        component="span"
        sx={joinSx([promoPrice && classes.mainPriceDiscount])}
      >
        {`฿${price}`}
      </Box>
      {promoPrice ? (
        <Box component="span" sx={classes.promoPrice}>{`฿${promoPrice}`}</Box>
      ) : (
        ''
      )}
      <Box component="span" sx={classes.priceSep}>
        |
      </Box>
      <Box component="span">{`${weight} g`}</Box>
      {unitSold ? (
        <>
          {' '}
          <Box component="span" sx={classes.priceSep}>
            |
          </Box>
          <Box
            component="span"
            sx={{ color: '#435244' }}
          >{`${unitSold} sold`}</Box>
        </>
      ) : null}
    </Typography>
  );
}

type productRateProps = {
  rate: Product['rating'];
};
function ProductRate({ rate }: productRateProps) {
  const classes = useStyles;

  const rating = rate?.overallRating || 0;

  if (!rating) {
    return <Box sx={{ height: '24px' }} />;
  }

  return (
    <Box sx={classes.productRate}>
      <RatingHeart
        color="green"
        size="xxs"
        sx={classes.rating}
        value={rating / 2}
        precision={0.25}
        readOnly
      />
    </Box>
  );
}

type productTitleProps = {
  title?: string;
};
const ProductTitle: React.FC<productTitleProps> = (props) => {
  const { title } = props;
  const classes = useStyles;

  return (
    <Typography variant="h4" component="p" sx={classes.productTitle}>
      {title || <Skeleton width="100%" />}
    </Typography>
  );
};

type productRoastLevelProps = {
  roastLevel: Product['roast'];
};
const ProductRoastLevel = (props: productRoastLevelProps): JSX.Element => {
  const { roastLevel } = props;
  return <CoffeeRoastLevel roastLevel={roastLevel} size="xs" />;
};

type productSellerProps = {
  seller?: string;
};
const ProductSeller: React.FC<productSellerProps> = (props) => {
  const { seller } = props;
  const classes = useStyles;

  return (
    <Typography
      sx={joinSx([
        classes.productSeller,
        !seller && { display: 'inline-block' },
      ])}
    >
      {seller || <Skeleton width="7em" />}
    </Typography>
  );
};

export type productCardProps = {
  product?: Omit<Product, 'description'>;
};
export function ProductCard({ product }: productCardProps): JSX.Element {
  const classes = useStyles;

  if (!product) {
    return (
      <Box width="100%" sx={classes.skeletonCard}>
        <Box sx={classes.productCard}>
          <Box sx={classes.cardMedia} />
          <ProductSeller />
          <ProductTitle />
          <ProductTaste />
        </Box>
      </Box>
    );
  }

  return (
    <Card sx={classes.productCard} variant="outlined">
      <Link
        href="/shop/[...productSlug]"
        as={`/shop/${product.shopSlug}/${product.productCode}`}
        passHref
      >
        <CardActionArea
          component="a"
          title={product.productName}
          sx={{ '& .MuiCardActionArea-focusHighlight': { margin: -2 } }}
        >
          <ProductImageDisplay
            url={product.img}
            stockStatus={product.stockStatus}
            width={360}
          />
          <ProductRate rate={product.rating} />
          <ProductSeller seller={product.shop} />
          <ProductTitle title={product.productName} />
          <ProductRoastLevel roastLevel={product.roast} />
          <ProductTaste tasteNote={product.tasteNotes} />
          <ProductPrice
            price={product.retailPrice}
            weight={product.weight}
            promoPrice={product.promoPrice}
            unitSold={product.unitSold}
          />
        </CardActionArea>
      </Link>
    </Card>
  );
}

const ProductNotFound = (): JSX.Element => {
  const { productListStateDispatch } = React.useContext(ProductContext);

  const handleClearSelection = () => {
    productListStateDispatch({ type: 'clear-all-select-facet' });
  };

  return (
    <Box
      sx={{
        paddingTop: '20vh',
        paddingBottom: '20vh',
      }}
    >
      <Typography variant="h6" component="p" align="center">
        Awww :( sorry
        <br />
        We cannot find any matches for your search term.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={handleClearSelection}
        >
          Reset Filters
        </Button>
      </Box>
    </Box>
  );
};

type productListProps = {
  productList?: getProductListReturn;
};
const ProductList: React.VFC<productListProps> = (props) => {
  const { productList } = props;

  if (productList === undefined) {
    // return skeleton
    return (
      <Grid spacing={4} container>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <ProductCard />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <ProductCard />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <ProductCard />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <ProductCard />
        </Grid>
      </Grid>
    );
  }

  const { data } = productList;

  if (data.length === 0) {
    return <ProductNotFound />;
  }

  const avaliableProduct = data.filter((p) => p.stockStatus !== 'Sold Out');
  const soldOutProduct = data.filter((p) => p.stockStatus === 'Sold Out');

  return (
    <Box>
      <Grid spacing={4} container>
        {avaliableProduct.map((product) => (
          <Grid
            key={`${product.shopSlug}-${product.productCode}`}
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
          >
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
      {soldOutProduct.length > 0 ? (
        <Box sx={{ mb: 4 }}>
          <CardSection
            title="Sold out"
            defaultOpen={false}
            variant="h6"
            color="common.darkGrey"
          >
            <Grid spacing={4} container sx={{ mt: 2 }}>
              {soldOutProduct.map((product) => (
                <Grid
                  key={`${product.shopSlug}-${product.productCode}`}
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  xl={3}
                >
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </CardSection>
        </Box>
      ) : null}
    </Box>
  );
};
export default ProductList;
