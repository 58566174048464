import { Caffe, CaffePromise } from 'apiclient';
import { AxiosRequestConfig } from 'axios';
import { PaginationMeta } from 'types/pagination';
import { ProductSortBy, ProductList, ProductFilterType } from 'types/product';

const caffe = new Caffe();

export type getProductListProps = { api?: 'getProductList' } & {
  axiosConfig?: AxiosRequestConfig;
} & {
  page: number;
  pageSize?: number;
  getAll?: boolean;
  filters?: ProductFilterType;
  sortBy?: ProductSortBy;
  shop?: string;
  showSoldOut?: boolean;
  showOutofStock?: boolean;
};

export interface getProductListReturn {
  data: ProductList;
  meta: PaginationMeta;
}

const getProductList = async (props: getProductListProps): CaffePromise<getProductListReturn> => {
  const { axiosConfig, ...functionProps } = props;

  // of all possiable breakpoints,
  // product list can be 2, 3, 4 columns,
  // 12 is the least common multiplier
  // use page size as 24 since 12 is a bit too small
  const defaultProps = {
    pageSize: 24,
    sortBy: 'priceAsc' as const,
  };
  const data: getProductListProps = {
    ...defaultProps,
    ...functionProps,
  };

  return caffe.request({
    ...{
      method: 'GET',
      url: '/api/method/caff.e.product.query.product_list',
      params: {
        page: data.page,
        page_size: data.pageSize,
        get_all: data.getAll,
        filters: data.filters,
        sort_by: data.sortBy,
        shop: data.shop,
        show_sold_out: data.showSoldOut,
        show_out_of_stock: data.showOutofStock,
      },
    },
    ...axiosConfig,
  });
};
export default getProductList;
