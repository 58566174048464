import useSWR from 'swr';

import {
  getProductDetail,
  getProductDetailProps,
  getProductDetailReturn,
} from 'api';

export type useGetProductDetailReturn = {
  isLoading: boolean;
  isError: boolean;
  data?: getProductDetailReturn;
};

const useGetProductDetail = (
  args: getProductDetailProps & { initialData?: getProductDetailReturn },
): useGetProductDetailReturn => {
  const { initialData, ...getProductDetailArgs } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getProductDetail' },
      ...getProductDetailArgs,
    },
    getProductDetail,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useGetProductDetail;
