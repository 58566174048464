import useSWR from 'swr';

import { getProductList, getProductListReturn, getProductListProps } from 'api';

export type useGetProductListReturn = {
  isLoading: boolean;
  isError: boolean;
  data?: getProductListReturn;
};

const useGetProductList = (
  args: getProductListProps & { initialData?: getProductListReturn },
): useGetProductListReturn => {
  const { initialData, ...getProductListArgs } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getProductList' },
      ...getProductListArgs,
    },
    getProductList,
    {
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useGetProductList;
