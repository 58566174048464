import { Caffe, CaffePromise } from 'apiclient';
import { ProductFacet } from 'types/facet';

const caffe = new Caffe();

export type getProductFacetProps = {
  api?: 'getProductFacet';
};

export type getProductFacetReturn = ProductFacet[];

const getProductFacet = async (): CaffePromise<getProductFacetReturn> => {
  return caffe.request({
    method: 'GET',
    url: '/api/method/caff.e.product.query.product_filter',
  });
};
export default getProductFacet;
