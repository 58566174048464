import useSWR from 'swr';

import {
  getProductFacet,
  getProductFacetReturn,
  getProductFacetProps,
} from 'api';

export type useGetProductFacetReturn = {
  isLoading: boolean;
  isError: boolean;
  data?: getProductFacetReturn;
};

const useGetProductFacet = (
  args: getProductFacetProps & { initialData?: getProductFacetReturn },
): useGetProductFacetReturn => {
  const { initialData, ...getProductFacetArgs } = args;

  const { data, error } = useSWR(
    {
      ...{ api: 'getProductFacet' },
      ...getProductFacetArgs,
    },
    getProductFacet,
    {
      revalidateOnMount: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      refreshInterval: 0,
      fallbackData: initialData,
    },
  );

  return {
    isLoading: !error && !data,
    isError: error,
    data,
  };
};
export default useGetProductFacet;
