import * as React from 'react';

import { getProductListReturn } from 'api';

import {
  productListReducerStateType,
  productListReducerActionType,
} from '../ProductListReducer';

export type ContextProps = {
  isLoading: boolean;

  productListState: productListReducerStateType;
  productListStateDispatch: React.Dispatch<productListReducerActionType>;

  productList: getProductListReturn | undefined;
};
const Context = React.createContext<ContextProps>({
  isLoading: true,

  productListState: {},
  productListStateDispatch: () => null,

  productList: undefined,
});
export default Context;
